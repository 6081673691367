import { motion } from 'framer-motion';
import { useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Img from 'components/img/Img';
import { ProfileLevelSteps } from 'components/profile/profile-levels/profile-level-step';
import { ArrowCollapseUp, Done, Lock } from 'components/svg';
import { spring } from 'constants/index';

import './index.scss';

const ProfileLevel = ({
    name,
    bonus,
    image,
    tasks,

    isLocked = true,
    isFinished = false,
    isCurrent = false,
    isFirst = false,
    isLast = false,
}) => {
    const [status, toggleStatus] = useState(false);
    const containerRef = useRef();

    const isActiveClass = useMemo(() => (status ? 'active' : ''), [status]);
    const isLastClass = useMemo(() => (isFirst ? 'last' : ''), [isFirst]);

    const levelSteps = useMemo(() => {
        if (bonus) {
            return [{ title: bonus, isBonus: true }, ...tasks];
        }

        return tasks;
    }, [tasks, bonus]);

    return (
        <motion.div
            transition={spring}
            animate={status ? 'open' : 'close'}
            onAnimationComplete={() => {
                if (isCurrent) {
                    containerRef.current.scrollIntoView({ behavior: 'smooth' });
                    toggleStatus(true);
                }
            }}
            ref={containerRef}
            className="profile-level-item-wrapper"
        >
            <div
                className={`profile-level-item ${ isActiveClass } ${ isLastClass }`}
                onClick={() => toggleStatus((prev) => !prev)}
            >
                <div className={`profile-level-item-left ${ isLocked ? 'profile-level-is-locked' : '' }`}>
                    <div className="profile-level-item-image">
                        {image && typeof image === 'string' ? <Img src={image} alt="level logo" /> : image}
                    </div>
                </div>

                <div className="profile-level-item-right">
                    <div className={`profile-level-item-name ${ isLocked ? 'profile-level-is-locked' : '' }`}>
                        <h2>
                            <FormattedMessage id={name} />
                        </h2>

                        {isLocked && !isFinished && <Lock fill="gray" />}

                        {isCurrent && (
                            <div className="profile-level-item-current">
                                <Done />
                                <p>
                                    <FormattedMessage id="level.levels.current-rank-text" />
                                </p>
                            </div>
                        )}
                    </div>
                    {!isFirst && (
                        <ArrowCollapseUp
                            className="ml-3"
                            style={{
                                width:     '1.2rem',
                                height:    '1.2rem',
                                transform: status ? '' : 'rotate(180deg)',
                            }}
                        />
                    )}
                </div>
            </div>

            {status && (
                <div
                    className={`profile-level-item-details d-flex flex-column align-items-end ${
                        isLocked ? 'profile-level-is-locked' : ''
                    }`}
                >
                    <ProfileLevelSteps steps={levelSteps} />
                </div>
            )}
        </motion.div>
    );
};

export default ProfileLevel;
