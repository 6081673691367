import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

import * as types from './levels.types';

export const getAllLevels = () => async function (dispatch) {
    dispatch({ type: types.LEVELS_GET_ALL_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.levels.get);

        if (response.status == 200) {
            dispatch(getAllLevelsSuccess(response.data));
        } else {
            dispatch(getAllLevelsError(response));
        }
    } catch (error) {
        dispatch(getAllLevelsError(error));
    }
};

function getAllLevelsSuccess(results) {
    return {
        type:  types.LEVELS_GET_ALL_SUCCESS,
        data:  results,
        error: null,
    };
}

function getAllLevelsError(error) {
    return {
        type: types.LEVELS_GET_ALL_ERROR,
        data: null,
        error,
    };
}
