import { motion } from 'framer-motion';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FormattedMessage } from 'react-intl';

import { Checked } from 'components/svg';
import { levelStepProgressBarStyles } from 'constants/index';

import styles from './index.module.scss';

const stepsVariants = {
    hidden: {
        y: -20,
    },
    visible: {
        y: 0,
    },
    exit: {
        y:          -20,
        opacity:    0,
        transition: {
            duration:        0.2,
            ease:            'easeOut',
            staggerChildren: 0.3,
        },
    },
};

function ProfileLevelSteps({ steps }) {
    return (
        <motion.div variants={stepsVariants} initial="hidden" animate="visible" exit="exit" className={styles.steps}>
            {steps
                ? steps.map((step, idx) => (
                    <ProfileLevelStep
                        key={idx}
                        subtitle={step?.subtitle}
                        title={step?.title}
                        progress={step?.progress}
                        isBonus={step?.isBonus}
                        isComplete={step?.isComplete}
                        hasAction={step?.hasAction}
                        onActionClick={step?.onActionClick}
                        actionDisplayedText={step?.actionDisplayedText}
                    />
                ))
                : 'no steps found'}
        </motion.div>
    );
}

const stepVariants = {
    head: {
        hidden: {
            y:       50,
            opacity: 0,
        },
        visible: {
            y:       0,
            opacity: 1,
        },
    },
    progress: {
        hidden: {
            y:       50,
            opacity: 0,
        },
        visible: {
            y:       0,
            opacity: 1,
        },
    },
};

function ProfileLevelStep({
    subtitle,
    title,
    progress,
    isComplete,
    isBonus = false,
    hasAction = false,
    onActionClick,
    actionDisplayedText,
}) {
    return (
        <motion.div layout transition={{ staggerChildren: 0.2 }} className={styles.step}>
            <motion.div variants={stepVariants.head} className={styles.stepHead}>
                {!!subtitle && (
                    <p>
                        <FormattedMessage id={subtitle} />
                    </p>
                )}
                <h1 className={(isComplete ? styles.isComplete : '') + (isBonus ? ` ${ styles.isBonus }` : '')}>
                    {typeof title === 'string' ? (
                        <FormattedMessage id={title} />
                    ) : (
                        <FormattedMessage id={title.id} values={title.values} />
                    )}
                </h1>
                {hasAction && (
                    <p onClick={onActionClick} className={styles.stepAction}>
                        <FormattedMessage id={actionDisplayedText} />
                    </p>
                )}
            </motion.div>

            <motion.div variants={stepVariants.progress} className={styles.stepProgress}>
                {progress != null && !isComplete && (
                    <CircularProgressbar strokeWidth={6} value={progress} styles={levelStepProgressBarStyles} />
                )}

                {isComplete && <Checked />}
            </motion.div>
        </motion.div>
    );
}

export { ProfileLevelStep, ProfileLevelSteps };
