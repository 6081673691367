import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useToast from 'components/widgets/toast/useToast';
import { isValidPassword } from 'services/validation';

import styles from './index.module.scss';
import { updateUserPassword } from './index.service';

const variants = {
    hidden: {
        y:          1000,
        transition: { duration: 0.3 },
    },
    visible: {
        y:          0,
        transition: {
            type:      'spring',
            damping:   30,
            stiffness: 300,
        },
    },
};

const ChangePassword = ({ show, onClose }) => {
    const intl = useIntl();
    const { triggerToast } = useToast();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleDragEnd = (event, info) => {
        if (info.offset.y > 250) {
            onClose();
        }
    };

    const clearForm = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    };

    const onSubmit = async () => {
        if (newPassword !== confirmNewPassword) {
            triggerToast(intl.formatMessage({ id: 'warn.password.validate.no-match' }), 'warn');

            return;
        }

        const validationResult = isValidPassword(newPassword);

        if (!validationResult.valid) {
            triggerToast(
                intl.formatMessage({ id: validationResult.messageId }),
                validationResult.messageType || 'error',
            );

            return;
        }

        if (newPassword === currentPassword) {
            triggerToast(intl.formatMessage({ id: 'warn.password.validate.old-vs-new' }), 'warn');

            return;
        }

        const result = await updateUserPassword({ currentPassword, newPassword });

        if (!result || result.success == false) {
            triggerToast(intl.formatMessage({ id: 'warn.password.validate.current-invalid' }), 'warn');

            return;
        }

        triggerToast(intl.formatMessage({ id: 'success.password.updated' }), 'success');

        clearForm();
        onClose();
    };

    return (
        <AnimatePresence>
            {show && (
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.6 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={styles.backDrop}
                    />
                    <motion.div
                        className={`form-container ${ styles.container }`}
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={{ top: 0, bottom: 0.8 }}
                        onDragEnd={handleDragEnd}
                    >
                        <div className={styles.dragHandle} />

                        <h2>
                            <FormattedMessage id="profile.change-password.title" />
                        </h2>

                        <label className="color-white mb-2" htmlFor="current-password">
                            <FormattedMessage id="profile.change-password.current-password" />
                        </label>
                        <div className="input-wrapper">
                            <input
                                id="current-password"
                                name="current-password"
                                type="password"
                                placeholder={intl.formatMessage({
                                    id: 'profile.change-password.current-password.placeholder',
                                })}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </div>
                        <label className="color-white mb-2" htmlFor="new-password">
                            <FormattedMessage id="profile.change-password.new-password" />
                        </label>
                        <div className="input-wrapper">
                            <input
                                id="new-password"
                                name="new-password"
                                type="password"
                                placeholder={intl.formatMessage({
                                    id: 'profile.change-password.new-password.placeholder',
                                })}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <label className="color-white mb-2" htmlFor="confirm-new-password">
                            <FormattedMessage id="profile.change-password.confirm-new-password" />
                        </label>
                        <div className="input-wrapper">
                            <input
                                id="confirm-new-password"
                                name="confirm-new-password"
                                type="password"
                                placeholder={intl.formatMessage({
                                    id: 'profile.change-password.confirm-new-password.placeholder',
                                })}
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                        </div>
                        <button type="button" className="type-submit" onClick={onSubmit}>
                            <FormattedMessage id="profile.change-password.button" />
                        </button>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default ChangePassword;
