import ReactDropdown, { ReactDropdownProps } from 'react-dropdown';

import './dropdown.scss';

type Props = ReactDropdownProps;

const Dropdown = (props: Props) => (
    <ReactDropdown {...props}/>
);

export default Dropdown;
