import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

const ProfileLevelIntro = () => (
    <div className={styles.introContainer}>
        <h1>
            <FormattedMessage id="level.levels-intro.header" />
        </h1>
        <p>
            <FormattedMessage id="level.levels-intro.description" />
        </p>
    </div>
);

export default ProfileLevelIntro;
