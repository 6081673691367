import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import IntroTip from 'components/widgets/intro-tip/IntroTip';
import { introTooltipsKeys } from 'constants/introTooltips';
import useIntroTooltipAPI from 'hooks/intro-helper/useIntroTooltipAPI';
import useScrollHandler from 'hooks/intro-helper/useScrollHandler';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';
import { updateTooltipKey } from 'store/user/action';

const ProfileFirstVisitIntro = ({ isLoading }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const scrollHandler = useScrollHandler();
    const tooltipAPI = useIntroTooltipAPI();
    const currentUser = useGetCurrentUser();
    const [introState, setIntroState] = useState({
        stepsEnabled: false,
        initialStep:  0,
        steps:        [
            {
                element: '#profile-levels-card',
                intro:   intl.formatMessage({ id: 'profile-page-intro.explain-offer.step-1.text' }),
            },
            {
                element: '#profile-history-card',
                intro:   intl.formatMessage({ id: 'profile-page-intro.explain-offer.step-2.text' }),
            },
        ],
    });

    const onIntroComplete = () => {
        tooltipAPI.updateValue({ key: introTooltipsKeys.tooltipProfile, viewed: true });
        dispatch(updateTooltipKey(introTooltipsKeys.tooltipProfile, true));
        setIntroState((prev) => ({ ...prev, stepsEnabled: false }));

        scrollHandler && scrollHandler.enableScroll();
    };

    const showIntro = () => {
        setIntroState((prev) => ({ ...prev, stepsEnabled: true }));

        scrollHandler && scrollHandler.disableScroll();
    };

    useEffect(() => {
        if (currentUser?.tooltips?.HELP_TOOLTIP_PROFILE === false && !isLoading) {
            setTimeout(() => showIntro(), 500);
        }
    }, [isLoading]);

    return (
        <IntroTip
            hideHeader
            hidePrevButton
            doneButtonText={intl.formatMessage({ id: 'profile-page-intro.explain-offer.step-2.button-text' })}
            nextButtonText={intl.formatMessage({ id: 'profile-page-intro.explain-offer.step-1.button-text' })}
            stepsEnabled={introState.stepsEnabled}
            steps={introState.steps}
            initialStep={introState.initialStep}
            onComplete={onIntroComplete}
        />
    );
};

export default ProfileFirstVisitIntro;
