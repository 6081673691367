import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

export async function updateUserPassword({ currentPassword, newPassword }) {
    try {
        const response = await TaooServerClient.post(endpoints.profile.updatePassword, {
            oldPassword: currentPassword,
            newPassword,
        });

        if (!response) {
            throw new Error('API_ERROR');
        }

        if ('success' in response && response?.success == false) {
            return { success: false };
        }

        return response.data;
    } catch (error) {
        console.error(error);
    }
}
