import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import Loader from 'components/loaders/loader';
import { Levels } from 'constants/levels';
import useGetLevels from 'hooks/levels/useGetLevels';
import useLocale from 'hooks/locale/useLocale';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { neitherNullNorUndefined } from 'services/validation';
import { resumeOnboarding } from 'store/step-process/action';

import styles from './index.module.scss';
import ProfileLevel from './profile-level';
import ProfileLevelIntro from './profile-level-intro';

const levelsArray = Object.values(Levels);

function ProfileLevels() {
    const intl = useIntl();
    const lang = useLocale();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data, isLoading } = useGetLevels();
    const currentUser = useGetCurrentUser();

    const formattedData = useMemo(() => {
        if (!data) return [];

        return formatLevels(data.levels, data.currentLevel, lang, navigate, dispatch, currentUser.onboarded);
    }, [data, currentUser]);

    return (
        <PageTransitionWrapper>
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.levels' })} />

            <div className={styles.container}>
                <ProfileLevelIntro />

                {isLoading && <Loader />}
                {!isLoading && formattedData.length > 0
                    ? formattedData.map((props, idx) => <ProfileLevel key={idx} {...props} />)
                    : null}
            </div>
        </PageTransitionWrapper>
    );
}

const formatLevels = (levels, currentLevel, lang, navigate, dispatch, isOnBoarded) => levels?.length > 0
    ? levels.map((level, idx) => {
        const isFinished = level.done;
        const isCurrent = level.number === currentLevel;
        const isLocked = level.number > currentLevel;
        const isFirst = idx === 0;
        const isLast = idx === levels.length - 1;
        const levelStaticData = levelsArray[level.number];

        const tasks =
                  idx === 0
                      ? []
                      : formatLevelTasks(levels[idx - 1].tasks, levelStaticData, lang, navigate, dispatch, isOnBoarded);

        return {
            isFinished,
            isCurrent,
            isLocked,
            isFirst,
            isLast,
            image: levelStaticData?.image,
            tasks,
            name:  levelStaticData?.name,
            bonus: levelStaticData?.bonus,
        };
    })
    : [];

const formatLevelTasks = (tasks, levelStaticData, lang, navigate, dispatch, isOnBoarded) => tasks?.length > 0
    ? tasks.map((task) => {
        const taskStaticData = levelStaticData.tasks.find((st) => st.key === task.label);
        let title = taskStaticData?.description;
        let subtitle = taskStaticData?.subDescription;

        if (!subtitle) {
            if (taskStaticData?.key?.includes('ACCOUNT_AGE')) {
                subtitle = title;

                // title = `${ task.progress } of ${ task.goal } days`;
                title = {
                    id:     'level.level-x.account-age-result',
                    values: {
                        progress: task.progress,
                        goal:     task.goal,
                    },
                };
            }

            if (taskStaticData?.key?.includes('CONNECT') && taskStaticData?.key?.includes('DAYS')) {
                subtitle = title;

                // title = `${ task.progress } of ${ task.goal } days`;
                title = {
                    id:     'level.level-x.connect-result',
                    values: {
                        progress: task.progress,
                        goal:     task.goal,
                    },
                };
            }
        }

        return {
            title,
            subtitle,
            progress:   task.progressPercent,
            isComplete: task.done,

            // prettier-ignore
            hasAction: neitherNullNorUndefined(taskStaticData?.actionDisplayedText) &&
                       ((taskStaticData?.shouldShowActionOnceComplete === true && task.done) || !task.done) &&
                       ((taskStaticData.key === 'LEVEL_1_ONBOARDING' && !isOnBoarded) || taskStaticData.key !== 'LEVEL_1_ONBOARDING'),
            actionDisplayedText: taskStaticData?.actionDisplayedText,
            onActionClick:       () => {
                if (taskStaticData.key === 'LEVEL_1_ONBOARDING') {
                    dispatch(resumeOnboarding());

                    setTimeout(() => {
                        navigate(All_PATHS_NAMESPACES[lang].onboarding.path);
                    }, 100);
                }
            },
        };
    })
    : [];

export default ProfileLevels;
