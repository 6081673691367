import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

import * as types from './profile.types';

export const getProfile = () => async function (dispatch) {
    dispatch({ type: types.PROFILE_GET_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.profile.get);

        if (response.status == 200) {
            dispatch(getProfileSuccess(response.data));
        } else {
            dispatch(getProfileError(response));
        }
    } catch (error) {
        dispatch(getProfileError(error));
    }
};

function getProfileSuccess(results) {
    return {
        type:  types.PROFILE_GET_SUCCESS,
        data:  results,
        error: null,
    };
}

function getProfileError(error) {
    return {
        type: types.PROFILE_GET_ERROR,
        data: null,
        error,
    };
}

export const getProfileInfo = () => async function (dispatch) {
    dispatch({ type: types.PROFILE_GET_INFO_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.profile.getInfo);

        if (response.status == 200) {
            dispatch(getProfileInfoSuccess(response.data));
        } else {
            dispatch(getProfileInfoError(response.error));
        }
    } catch (error) {
        dispatch(getProfileInfoError(error));
    }
};

function getProfileInfoSuccess(results) {
    return {
        type:  types.PROFILE_GET_INFO_SUCCESS,
        info:  results,
        error: null,
    };
}

function getProfileInfoError(error) {
    return {
        type: types.PROFILE_GET_INFO_ERROR,
        info: null,
        error,
    };
}

export const updateProfileInfo = (payload) => async function (dispatch) {
    dispatch({ type: types.PROFILE_UPDATE_REQUEST });

    try {
        const response = await TaooServerClient.post(endpoints.profile.updateInfo, payload);

        if (response.status == 200) {
            dispatch(updateProfileInfoSuccess());
        } else {
            dispatch(updateProfileInfoError(response.error));
        }
    } catch (error) {
        dispatch(updateProfileInfoError(error));
    }
};

function updateProfileInfoSuccess() {
    return {
        type:  types.PROFILE_UPDATE_SUCCESS,
        error: null,
    };
}

function updateProfileInfoError(error) {
    return {
        type: types.PROFILE_UPDATE_ERROR,
        error,
    };
}

export const updateProfileAvatar = (payload) => async function (dispatch) {
    dispatch({ type: types.PROFILE_UPDATE_AVATAR_REQUEST });

    try {
        const response = await TaooServerClient.post(endpoints.profile.updateAvatar, payload);

        if (response.status == 200) {
            dispatch(updateProfileAvatarSuccess());
        } else {
            dispatch(updateProfileAvatarError(response.error));
        }
    } catch (error) {
        dispatch(updateProfileAvatarError(error));
    }
};

function updateProfileAvatarSuccess() {
    return {
        type:  types.PROFILE_UPDATE_AVATAR_SUCCESS,
        error: null,
    };
}

function updateProfileAvatarError(error) {
    return {
        type: types.PROFILE_UPDATE_AVATAR_ERROR,
        error,
    };
}
