import { LevelAvatar } from 'components/svg';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

export const levelsData = {
    level: 0,
    taskN: {
        done:   false,
        detail: {
            progress: 0,
            goal:     2,
            unit:     ['DAYS', 'MONEY', 'POINT'],
        },
    },
};

export const Levels = {
    level0: {
        levelIndex: 0,
        name:       'level.level-0.name',
        image:      <LevelAvatar levelNumber={0} />,
        multiplier: 1,
        tasks:      [],
    },
    level1: {
        levelIndex: 1,
        name:       'level.level-1.name',
        image:      <LevelAvatar levelNumber={1} />,
        multiplier: 1,
        tasks:      [
            {
                key:                          'LEVEL_1_ONBOARDING',
                description:                  'level.level-1.task-1.description',
                actionDisplayedText:          'help-and-contact.menu.onboarding-toggler.title',
                shouldShowActionOnceComplete: true,
            },
        ],
    },
    level2: {
        levelIndex: 2,
        name:       'level.level-2.name',
        image:      <LevelAvatar levelNumber={2} />,
        multiplier: 1,
        tasks:      [
            {
                key:         'LEVEL_2_BUY_FIRST_DISCOUNT',
                description: 'level.level-2.task-1.description',
            },
        ],
    },
    level3: {
        levelIndex: 3,
        name:       'level.level-3.name',
        image:      <LevelAvatar levelNumber={3} />,
        multiplier: 1,
        tasks:      [
            {
                key:         'LEVEL_3_ADD_1_FRIEND',
                description: 'level.level-3.task-1.description',
            },
            {
                key:         'LEVEL_3_CONNECT_2_DAYS',
                description: 'level.level-3.task-2.description',
            },
        ],
    },
    level4: {
        levelIndex: 4,
        name:       'level.level-4.name',
        image:      <LevelAvatar levelNumber={4} />,
        multiplier: 1,
        tasks:      [
            {
                key:         'LEVEL_4_CONNECT_3_DAYS',
                description: 'level.level-4.task-1.description',
            },
            {
                key:         'LEVEL_4_COMPLETE_PROFILE',
                description: 'level.level-4.task-2.description',
            },
        ],
    },
    level5: {
        levelIndex: 5,
        name:       'level.level-5.name',
        image:      <LevelAvatar levelNumber={5} />,
        multiplier: 1,
        tasks:      [
            {
                key:         'LEVEL_5_USE_TAOO',
                description: 'level.level-5.task-1.description',
            },
            {
                key:         'LEVEL_5_ADD_5_FRIEND',
                description: 'level.level-5.task-2.description',
            },
            {
                key:         'LEVEL_5_ACCOUNT_AGE_10',
                description: 'level.level-5.task-3.description',
            },
        ],
    },
    level6: {
        levelIndex: 6,
        name:       'level.level-6.name',
        bonus:      'level.level-6.level-bonus',
        image:      <LevelAvatar levelNumber={6} />,
        multiplier: 1.1,
        tasks:      [
            {
                key:         'LEVEL_6_ACCOUNT_AGE_15_OR_MORE',
                description: 'level.level-6.task-1.description',
            },
            {
                key:            'LEVEL_6_VALID_AMS_15',
                description:    'level.level-6.task-2.description',
                subDescription: 'level.level-6.task-2.sub-description',
            },
        ],
    },
    level7: {
        levelIndex: 7,
        name:       'level.level-7.name',
        bonus:      'level.level-7.level-bonus',
        image:      <LevelAvatar levelNumber={7} />,
        multiplier: 1.2,
        tasks:      [
            {
                key:         'LEVEL_7_ACCOUNT_AGE_20_OR_MORE',
                description: 'level.level-7.task-1.description',
            },
            {
                key:            'LEVEL_7_VALID_AMS_20',
                description:    'level.level-7.task-2.description',
                subDescription: 'level.level-7.task-2.sub-description',
            },
        ],
    },
    level8: {
        levelIndex: 8,
        name:       'level.level-8.name',
        bonus:      'level.level-8.level-bonus',
        image:      <LevelAvatar levelNumber={8} />,
        multiplier: 1.4,
        tasks:      [
            {
                key:         'LEVEL_8_ACCOUNT_AGE_30_OR_MORE',
                description: 'level.level-8.task-1.description',
            },
            {
                key:            'LEVEL_8_VALID_AMS_30',
                description:    'level.level-8.task-2.description',
                subDescription: 'level.level-8.task-2.sub-description',
            },
        ],
    },
    level9: {
        levelIndex: 9,
        name:       'level.level-9.name',
        bonus:      'level.level-9.level-bonus',
        image:      <LevelAvatar levelNumber={9} />,
        multiplier: 1.7,
        tasks:      [
            {
                key:         'LEVEL_9_ACCOUNT_AGE_60_OR_MORE',
                description: 'level.level-9.task-1.description',
            },
            {
                key:            'LEVEL_9_VALID_AMS_60',
                description:    'level.level-9.task-2.description',
                subDescription: 'level.level-9.task-2.sub-description',
            },
        ],
    },
    level10: {
        levelIndex: 10,
        name:       'level.level-10.name',
        bonus:      'level.level-10.level-bonus',
        image:      <LevelAvatar levelNumber={10} />,
        multiplier: 2,
        tasks:      [
            {
                key:         'LEVEL_10_ACCOUNT_AGE_90_OR_MORE',
                description: 'level.level-10.task-1.description',
            },
            {
                key:            'LEVEL_10_VALID_AMS_90',
                description:    'level.level-10.task-2.description',
                subDescription: 'level.level-10.task-2.sub-description',
            },
        ],
    },
};
