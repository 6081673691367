import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { signalNotification } from 'oneSignal';
import { useNavigate, useSearchParams } from 'react-router-dom';

import helpIcon from 'assets/Images/help.png';
import historyIcon from 'assets/Images/history.png';
import referrerIcon from 'assets/Images/referrer.png';
import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import ProfileAvatar from 'components/profile/profile-avatar';
import CardSmall from 'components/widgets/card-small';
import { domain } from 'constants/api';
import { Levels } from 'constants/levels';
import useLocale from 'hooks/locale/useLocale';
import useGetProfile from 'hooks/profile/useGetProfile';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { clearProcess } from 'store/step-process/action';
import { setCurrentUser } from 'store/user/action';
import { Wallet } from 'components/svg';

import ProfileFirstVisitIntro from './first-visit-intro';
import styles from './index.module.scss';
import DeleteAccount from './delete-account/DeleteAccount';

const LevelsArray = Object.values(Levels);

function Profile() {
    const intl = useIntl();
    const locale = useLocale()
    const navigate = useNavigate();
    const { data, isLoading } = useGetProfile();
    const language = useLocale();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const deleteProfile = searchParams.get("d");

    const handleLogout = () => {
        signalNotification.logout();
        
        dispatch(clearProcess());
        dispatch(setCurrentUser({}));
    };

    const goToPolicy = () => {
        navigate(All_PATHS_NAMESPACES[locale]['privacy-policy'].path);
    }

    const avatar = useMemo(() => {
        if (!data || !data?.avatar) {
            return null;
        }

        return `${ domain }/uploads/${ data.avatar }`;
    }, [data]);

    const { image: levelSVG } = LevelsArray[data?.level?.currentLevel ?? 0];

    return data ? (
        <PageTransitionWrapper>
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.profile' })} />

            <ProfileFirstVisitIntro isLoading={isLoading} />

            <div className="profile-content d-flex flex-grow-1 flex-column pb-md-5 pb-3">
                <ProfileAvatar image={avatar} progressPercentage={data?.completedProfile} />

                <div className={`${ styles.profileDetails } text-center`}>
                    <h2>{data?.name}</h2>
                    {!!data?.accountAge && (
                        <p>
                            <FormattedMessage id="profile.account-age" values={{ age: data?.accountAge }} />
                        </p>
                    )}
                    {!!data?.connectedDaysInRow && (
                        <p>
                            <FormattedMessage
                                id="profile.connected-days-in-row"
                                values={{ days: data?.connectedDaysInRow }}
                            />
                        </p>
                    )}
                </div>

                {/* <ProfileLevels wrapperId="profile-levels-card" data={data?.level} /> */}
                <CardSmall
                    id="profile-levels-card"
                    imagePlaceholder={levelSVG}
                    imageHolderClassName={styles.levelsImage}
                    onClickNavigateTo={All_PATHS_NAMESPACES[language].levels.path}
                    subtitle={<FormattedMessage id="profile.level.rank" />}
                    title={
                        <>
                            <FormattedMessage id="profile.level.level" /> {data?.level?.currentLevel || 0}
                        </>
                    }
                />

                <CardSmall
                    imagePlaceholder={<Wallet style={{ width: '100%', height: '100%' }} />}
                    onClickNavigateTo={All_PATHS_NAMESPACES[language].wallet.path}
                    title={'Mon portefeuille'}
                />
                <CardSmall
                    image={referrerIcon}
                    onClickNavigateTo={All_PATHS_NAMESPACES[language].referrals.path}
                    subtitle={<FormattedMessage id="profile.referral.card-subtitle" />}
                    title={<FormattedMessage id="profile.referral.card-title" />}
                />
                {/* <CardSmall image={cashIcon} title="Buy now Pay later" /> */}
                <CardSmall
                    onClickNavigateTo={All_PATHS_NAMESPACES[language].edit_tags.path}
                    imagePlaceholder={<TagLabel />}
                    title={<FormattedMessage id="profile.tags.card-text" />}
                />
                <CardSmall
                    id="profile-history-card"
                    onClickNavigateTo={All_PATHS_NAMESPACES[language].order_history.path}
                    image={historyIcon}
                    subtitle={<FormattedMessage id="profile.history.card-subtitle" />}
                    title={<FormattedMessage id="profile.history.card-text" />}
                />
                <CardSmall
                    onClickNavigateTo={All_PATHS_NAMESPACES[language]['help-and-contact'].path}
                    image={helpIcon}
                    title={<FormattedMessage id="profile.help.card-text" />}
                />

                <div className={styles.bottomCards}>
                    <CardSmall
                        // onClick={() => setShowDeleteAccount(true)}
                        onClick={() => setSearchParams({ d: true })}
                        title={<FormattedMessage id="profile.help.delete-account" />}
                    />

                    <CardSmall
                        onClick={handleLogout}  
                        title={<FormattedMessage id="profile.logout" />}
                    />

                    <CardSmall
                        onClick={goToPolicy}  
                        title={<FormattedMessage id="profile.policy" />}
                    />
                </div>
            </div>

            <DeleteAccount
                show={deleteProfile === 'true'}
                onClose={() => setSearchParams({ d: false })}
                // show={showDeleteAccount}
                // onClose={() => setShowDeleteAccount(false)}
            />
        </PageTransitionWrapper>
    ) : null;
}

function TagLabel() {
    return (
        <div
            style={{
                width:        '100%',
                height:       '100%',
                background:   'rgba(240, 197, 20, 1)',
                borderRadius: '0.87rem',
                marginRight:  '15px',
                display:      'grid',
                placeItems:   'center',
                fontSize:     '2.5rem',
            }}
        >
            #
        </div>
    );
}

export default Profile;
