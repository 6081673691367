import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import { progressBarStyles } from 'constants/index';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';

import styles from './index.module.scss';

function ProfileAvatar({ image, progressPercentage }) {
    const navigate = useNavigate();
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setPercentage(progressPercentage ?? 0);
    }, [progressPercentage]);

    const { isLoading, lang } = useSelector((state) => {
        const { dataIsLoading } = state.profileReducer;
        const lang = state.locale;

        return { isLoading: dataIsLoading, lang };
    });

    const handleAvatarClick = () => {
        navigate(All_PATHS_NAMESPACES[lang].edit_profile.path);
    };

    return (
        <div onClick={handleAvatarClick} className={`${ styles['avatar-container'] } avatar-container pos-relative`}>
            <CircularProgressbar strokeWidth={3} value={percentage} styles={progressBarStyles} />
            <div className={`${ styles['percentage-container'] } percentage-container bg-yellow color-back bold`}>
                <FormattedMessage
                    id="profile.level-percentage"
                    values={{
                        value: percentage,
                    }}
                />
            </div>
            <div className={`${ styles['svg-wrapper'] }`}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.83785 0.974377L7.14472 0.667505C8.03473 -0.222502 9.47718 -0.222502 10.3672 0.667505C11.2572 1.55751 11.2572 2.99996 10.3672 3.88994L10.0603 4.19682L6.83785 0.974377Z"
                        fill="white"
                    />
                    <path
                        d="M9.41577 4.84134L4.75091 9.5062L1.52847 6.28376L6.19333 1.6189L9.41577 4.84134Z"
                        fill="white"
                    />
                    <path
                        d="M0.00925636 10.565L0.883973 7.00495V6.98964C0.883973 6.97434 0.883973 6.97434 0.89928 6.95892L4.07568 10.1353C4.07568 10.1353 4.06038 10.1353 4.06038 10.1506H4.04507L0.485043 11.0254C0.454323 11.0254 0.423709 11.0407 0.392989 11.0407C0.285522 11.0407 0.193471 10.9946 0.116834 10.9332C0.0246744 10.8412 -0.0213518 10.7032 0.00936842 10.565L0.00925636 10.565Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div className={`${ styles.avatar } bold color-white`}>
                {!image && isLoading ? <Loader /> : <Img src={image} alt="user avatar" />}
            </div>
        </div>
    );
}

export default ProfileAvatar;
