import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProfile } from 'store/profile/profile.action';

export default function useGetProfile({ shouldFetch, ignoreRefetch } = { shouldFetch: true, ignoreRefetch: false }) {
    const dispatch = useDispatch();
    const {
        data, dataError, dataIsLoading, isUpdated, isAvatarUpdated, isOnBoarded,
    } = useSelector((state) => {
        const profile = state.profileReducer;
        const isOnBoarded = state.user.onboarded;

        return {
            ...profile,
            isOnBoarded,
        };
    });

    useEffect(() => {
        if (dataIsLoading || !shouldFetch || (ignoreRefetch && data)) {
            return;
        }

        // if (isEmpty(data) || isAvatarUpdated || isUpdated) {
        dispatch(getProfile());

        // }
    }, [isUpdated, isAvatarUpdated]);

    // useEffect(() => {
    //     if (isOnBoarded) {
    //         dispatch(getProfile())
    //     }
    //  }, [isOnBoarded])

    const fetch = () => dispatch(getProfile());

    return {
        data,
        error:     dataError,
        isLoading: dataIsLoading,
        fetch,
    };
}
