import { useState } from 'react';

import './deleteAccount.scss';
import Dropdown from 'components/widgets/dropdown/Dropdown';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import PrimaryButton from 'components/form/button/PrimaryButton';
import TaooServerClient from 'http/axios';
import { endpoints } from 'constants/api';
import useToast from 'components/widgets/toast/useToast';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from 'components/loaders/loader';

interface Props {
    show: boolean;
    onClose: () => void;
}

const options = [
    { value: '1', label: 'Problème de confidentialité' },
    { value: '2', label: 'Je ne trouve aucun compte à suivre' },
    { value: '3', label: 'Prend trop de temps' },
    { value: '4', label: 'Trop de publicités' },
    { value: '5', label: 'Je veux supprimer quelque chose' },
    { value: '6', label: 'Problème d\'utilisation' },
    { value: '7', label: 'J\'ai créé un second compte' },
    { value: '8', label: 'Autre chose' },
];

interface Return {
    message: string;
    success: boolean;
}

const sendRequest = async (reason: string): Promise<Return> => {
    try {
        const response = await TaooServerClient.post<Return>(endpoints.profile.deleteAccount, { reason });

        if (!response || !response.data) {
            throw new Error('API Error!');
        }

        return response.data;
    } catch (e) {
        console.log(e);
    }
};

const DeleteAccount = ({ show, onClose }: Props) => {
    const intl = useIntl();
    const { triggerToast } = useToast();
    const [selectedReason, setSelectedReason] = useState(null);
    const [isLoading, setLoader] = useState(false);

    const submit = () => {
        if (!selectedReason) {
            triggerToast(intl.formatMessage({ id: 'error.missingField' }), 'warn');

            return;
        }

        setLoader(true);

        sendRequest(selectedReason)
                .then((result) => {
                    if (result && (result?.message === 'ACTION_OK' || result?.success === true)) {
                        triggerToast('Vos données seront supprimées de nos systèmes.', 'success');
                        onClose?.();
                    } else {
                        triggerToast(intl.formatMessage({ id: 'RUNTIME_ERROR' }), 'error');
                    }
                })
                .finally(() => setLoader(false));
    };

    return (
        <DraggablePopup wrapperStyle={{ maxHeight: '95%' }} show={show} onClose={onClose}>
            <div className="delete-account">
                <h1><FormattedMessage id="profile-delete.title"/></h1>

                <p><FormattedMessage id="profile-delete.question"/></p>
                <Dropdown
                    onChange={(option) => setSelectedReason(option.label)}
                    placeholder="Choisir une option"
                    options={options}
                    className="delete-reason"
                />

                <p><FormattedMessage id="profile-delete.paragraph1"/></p>
                <p><FormattedMessage id="profile-delete.paragraph2"/></p>
                <PrimaryButton disabled={isLoading} isDanger onClick={submit}>
                    {isLoading ? <Loader /> : 'Confirmer'}
                </PrimaryButton>
            </div>
        </DraggablePopup>
    );
};

export default DeleteAccount;
